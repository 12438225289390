<template>
  <div class="main">
    <span>我的作业页面</span>
    <div class="el">
      <div class="el-item">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="已批阅" name="first">
          <div class="Homework-empty-list">
            <div class="Homework-empty">您还没有做过任何作业</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="批阅中" name="second">
          <div class="Homework-empty-list">
            <div class="Homework-empty">您还没有做过任何作业</div>
          </div>
        </el-tab-pane>
        </el-tabs>
      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  data() {
      return {
        activeName: 'first'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
}
</script>

<style lang="scss" scoped>
   .main{
    span{
      display: flex;
    }
    .el{
      border-top: 1px solid #f5f5f5;
      margin-top: 10px;
     .el-item{
        margin-top: 20px;
        .Homework-empty-list{
          list-style: none;
          margin: 0;
          padding: 0;
          .Homework-empty{
            text-align: center;
            color: #c1c1c1;
            padding: 20px 0;
            margin-top: 15px;
          }
        }
      }
    }
  }
</style>